import { useMemo } from 'react'
import { ApolloClient, useQuery } from '@apollo/client'
import {
  DiscountsListGuildCodesQuery,
  DiscountsListGuildCodesQueryVariables,
  GetGuildUserTicketsAvailableForSlugQuery,
  GuildRoles,
  GuildRolesQuery,
  TheatricalDiscount,
} from '@/types/codegen-federation'
import { hasAuthSessionCookie } from '@/utils/auth-utils'
import { isProductionEnvironment } from '@/utils/environment-utils'
import {
  USER_GUILD_MEMBERSHIP,
  GET_USER_GUILD_ROLES,
  GET_USER_GUILD_TICKETS_AVAILABLE,
  GET_USER_GUILD_TICKETS_DISCOUNT_CODES,
} from '../UserService/queries'

export const useGuildUser = () => {
  const { data, loading, refetch, startPolling, stopPolling } = useQuery<UserGuildMembershipQuery>(
    USER_GUILD_MEMBERSHIP,
    {
      skip: !hasAuthSessionCookie(),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
      errorPolicy: 'all',
    },
  )

  return useMemo(() => {
    return { loading, refetch, startPolling, stopPolling, ...parseGuildUser(data) }
  }, [data, loading, refetch, startPolling, stopPolling])
}

export enum GuildMemberReason {
  PIF_SUBSCRIBE = 'PIF_SUBSCRIBE',
  GUILD_SUBSCRIBE = 'GUILD_SUBSCRIBE',
}

export type GuildUser = {
  isGuildMember: boolean
  guildMemberReason: GuildMemberReason | null
  isSubscribedToGuild: boolean
  /**
   * Some perks are given to users based on them paying it forward, such as Early Access or Voting
   */
  isSubscribedToPifOrGuild: boolean
  /**
   * Indicates if the user has an active Guild membership because they are a paying Guild Member
   * At the time of this documentation, this means they are on the $20/month or $175/year plan
   * 20% discount on merch and free tickets to theatrical events are exclusive to this role
   */
  hasGuildMembership: boolean
  email?: string | null
  loading?: boolean
  guildRoles: GuildRoles
}

type UserGuildMembershipQuery = {
  user?: {
    isGuildMember: boolean
    guildMemberReason: GuildMemberReason | null
    email: string
    guildRoles: GuildRoles
  }
}

export function parseGuildUser(data: UserGuildMembershipQuery | undefined): GuildUser {
  if (!data)
    return {
      isGuildMember: false,
      isSubscribedToGuild: false,
      isSubscribedToPifOrGuild: false,
      hasGuildMembership: false,
      guildMemberReason: null,
      email: null,
      guildRoles: {},
    }

  const user = data?.user
  const isGuildMember = !!user?.isGuildMember
  const guildMemberReason = user?.guildMemberReason || null

  const isSubscribedToGuild = isGuildMember && guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE

  const isSubscribedToPifOrGuild =
    isGuildMember &&
    (guildMemberReason === GuildMemberReason.PIF_SUBSCRIBE || guildMemberReason === GuildMemberReason.GUILD_SUBSCRIBE)
  const hasGuildMembership = isGuildMember && GuildMemberReason.GUILD_SUBSCRIBE === guildMemberReason

  const guildRoles = user?.guildRoles || {}

  return {
    isGuildMember,
    guildMemberReason,
    isSubscribedToGuild,
    isSubscribedToPifOrGuild,
    hasGuildMembership,
    email: user?.email,
    guildRoles,
  }
}

/** @deprecated Get guildRoles from useGuildUser instead */
export const useUserGuildRoles = () => {
  const { data, loading } = useQuery<GuildRolesQuery>(GET_USER_GUILD_ROLES, {
    skip: !hasAuthSessionCookie(),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    errorPolicy: 'all',
  })

  return useMemo(() => {
    return { loading, data }
  }, [data, loading])
}

export function useUserGuildTickets({
  theatricalSlug,
  client,
  skip,
}: {
  theatricalSlug: string
  client: ApolloClient<object>
  skip?: boolean
}): { canClaimTickets: boolean; numberAvailable: number; discountCodes: TheatricalDiscount[]; loading: boolean } {
  const { data: tickets, loading: loadingUserGuild } = useQuery<GetGuildUserTicketsAvailableForSlugQuery>(
    GET_USER_GUILD_TICKETS_AVAILABLE,
    {
      client,
      skip: !hasAuthSessionCookie() || skip,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      refetchWritePolicy: 'overwrite',
      variables: { input: { theatricalSlug } },
      errorPolicy: 'all',
    },
  )

  const { data: discountCodes, loading: loadingDiscountCodes } = useQuery<
    DiscountsListGuildCodesQuery,
    DiscountsListGuildCodesQueryVariables
  >(GET_USER_GUILD_TICKETS_DISCOUNT_CODES, {
    client,
    skip: !hasAuthSessionCookie() || skip,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
    variables: { input: { theatricalSlug } },
    errorPolicy: 'all',
  })

  return useMemo(() => {
    const canClaimTickets = Boolean(tickets?.numberOfGuildTicketsAvailable?.count)
    const count = tickets?.numberOfGuildTicketsAvailable?.count ?? 0
    const codes = discountCodes?.discountsListGuildCodes?.discounts || []

    return {
      canClaimTickets,
      // staging always returns 1Million tickets to make testing easier
      // so, setting max = 2 if not in prod environment
      numberAvailable: isProductionEnvironment() ? count : Math.min(count, 2),
      discountCodes: codes,
      loading: loadingUserGuild || loadingDiscountCodes,
    }
  }, [tickets?.numberOfGuildTicketsAvailable?.count, loadingUserGuild, discountCodes, loadingDiscountCodes])
}
