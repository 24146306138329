import { useCallback, useMemo } from 'react'
import { logger } from '@/utils/logging'

interface MobileShareProps {
  shareUrl: string
  title?: string
  message?: string
}

export const useNativeMobileShare = () => {
  const share = useCallback(async ({ shareUrl, title, message }: MobileShareProps) => {
    const data = {
      title,
      text: message,
      shareUrl,
    }
    if (navigator?.canShare && navigator.canShare(data)) {
      try {
        await navigator.share(data)
      } catch (error) {
        logger().error(`An error occurred sharing ${shareUrl}`, error)
      }
    }
  }, [])

  return useMemo(() => {
    return { share }
  }, [share])
}
