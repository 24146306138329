import {
  AppRoute,
  BaseDeepLinkData,
  BaseBranchLinkRequest,
  MediaDeepLinkData,
  MediaDeepLinkRequest,
  MomentDeepLinkShareData,
  MomentShareDeepLinkRequest,
} from '@/services/DeepLinkService/types'

const domain = process.env.NEXT_PUBLIC_DOMAIN

const createBaseRequestData = (request: BaseBranchLinkRequest): BaseDeepLinkData => {
  const webUrl = `${domain}${request.path}`

  return {
    $desktop_url: webUrl,
    canonicalIdentifier: webUrl,
    route: request.route,
    title: request.contentName,
    destination: 'organic',
    publisher: 'Angel Studios',
    contentDescription: request.description,
  }
}
export const createEpisodeDeepLinkRequestData = (args: MediaDeepLinkRequest): MediaDeepLinkData => {
  return createMediaDeepLinkRequestData({
    ...args,
    isEpisode: true,
    route: 'VideoDetail',
  })
}

export const createLivestreamDeepLinkRequestData = (args: MediaDeepLinkRequest): MediaDeepLinkData => {
  return createMediaDeepLinkRequestData({
    ...args,
    isEpisode: false,
    route: 'LiveStreamViewer',
  })
}

export const createVideoDeepLinkRequestData = (args: MediaDeepLinkRequest): MediaDeepLinkData => {
  return createMediaDeepLinkRequestData({
    ...args,
    isEpisode: false,
    route: 'VideoDetail',
  })
}

export const createWatchDeepLinkRequestData = (args: MediaDeepLinkRequest): MediaDeepLinkData => {
  return createMediaDeepLinkRequestData({
    ...args,
    isEpisode: false,
    route: 'Watch',
  })
}

export const createCommunityStoryDeepLinkRequestData = (args: MediaDeepLinkRequest): MediaDeepLinkData => {
  return createMediaDeepLinkRequestData({
    ...args,
    isEpisode: false,
    route: 'Community',
  })
}

export const createMomentShareDeepLinkRequestData = ({
  timeSec,
  momentId,
  focusedOwnerId,
  ...args
}: MomentShareDeepLinkRequest): MomentDeepLinkShareData => {
  return {
    ...createMediaDeepLinkRequestData({
      ...args,
      isEpisode: true,
      route: 'VideoDetail',
    }),
    timeSec,
    momentId,
    focusedOwnerId,
    showSceneDetailOnLoad: true,
  }
}

const createMediaDeepLinkRequestData = ({
  guid,
  projectName,
  projectSlug,
  isEpisode,
  ...args
}: MediaDeepLinkRequest & { isEpisode: boolean; route: AppRoute }): MediaDeepLinkData => {
  return {
    guid,
    contentId: guid,
    project: projectName,
    theaterSlug: projectSlug,
    isEpisode,
    ...createBaseRequestData(args),
  }
}

export const createGuildDeepLinkRequestData = (args: BaseBranchLinkRequest): BaseDeepLinkData => {
  return createBaseRequestData({
    ...args,
    route: 'Guild',
    path: '/home#download',
  })
}
