import { getOrCreateUniqueBranchLink } from '@/services/DeepLinkService/DeepLinkService'
import { logger } from '@/utils/logging'

export type UtmParams = {
  source: string
  medium: string
  campaign: string
}

export interface BaseUrlConfig {
  baseUrl: string
  params?: Record<string, string>
}

export interface TrackingConfig extends BaseUrlConfig {
  userId: string
  slug: string
  aliasPrefix?: string
  utmParams?: UtmParams
  branchConfig?: {
    title?: string
    webOnly?: boolean
    customData?: Record<string, string>
  }
}

export const createBasicShareLink = async ({ baseUrl, params = {} }: BaseUrlConfig): Promise<string> => {
  const url = new URL(baseUrl)

  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  const urlRedirect = url.toString()

  const linkData = {
    $fallback_url: urlRedirect,
    $web_only: false,
  }

  try {
    return await getOrCreateUniqueBranchLink(linkData)
  } catch (error) {
    logger().error(`An error occurred creating link for ${baseUrl}`, error)
    return url.toString()
  }
}

export const createTrackingUrl = async ({
  baseUrl,
  slug,
  userId,
  utmParams,
  params = {},
  branchConfig = {},
}: TrackingConfig): Promise<string | null> => {
  const url = new URL(baseUrl)

  const trackingParams = {
    project: slug,
    product: 'presales',
    refuid: userId,
    ...params,
  }

  Object.entries(trackingParams).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  if (utmParams) {
    Object.entries(utmParams).forEach(([key, value]) => {
      url.searchParams.append(`utm_${key}`, value)
    })
  }

  const linkData = {
    $fallback_url: url.toString(),
    $web_only: branchConfig.webOnly ?? true,
    $marketing_title: branchConfig.title ?? 'Theatrical Giveaway',
    ...branchConfig.customData,
  }

  try {
    return await getOrCreateUniqueBranchLink(linkData)
  } catch (error) {
    logger().error(`An error occurred creating link for ${baseUrl}`, error)
    return url.toString()
  }
}
