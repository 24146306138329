import Bugsnag from '@bugsnag/js'
import { getCookie } from 'cookies-next'
import { SEGMENT_USER_ID_COOKIE } from '@/constants/cookies'
import {
  BaseDeepLinkData,
  BranchAnalytics,
  BaseBranchLinkRequest,
  MediaDeepLinkRequest,
  MomentShareDeepLinkRequest,
  UniqueLinkData,
} from '@/services/DeepLinkService/types'
import {
  createCommunityStoryDeepLinkRequestData,
  createEpisodeDeepLinkRequestData,
  createLivestreamDeepLinkRequestData,
  createMomentShareDeepLinkRequestData,
  createVideoDeepLinkRequestData,
  createGuildDeepLinkRequestData,
  createWatchDeepLinkRequestData,
} from '@/services/DeepLinkService/util'
import { logger } from '@/utils/logging'

const deepLinkService = process.env.NEXT_PUBLIC_ANGEL_DEEP_LINK_SERVICE ?? 'https://link-api.angelstudios.com/api'

type DeepLinkResponse = Promise<string | null>

export const createEpisodeDeepLink = async (
  props: MediaDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createEpisodeDeepLinkRequestData(props), analytics)
}

export const createLivestreamDeepLink = async (
  props: MediaDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createLivestreamDeepLinkRequestData(props), analytics)
}

export const createVideoDeepLink = async (
  props: MediaDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createVideoDeepLinkRequestData(props), analytics)
}

export const createWatchProjectDeepLink = async (
  props: MediaDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createWatchDeepLinkRequestData(props), analytics)
}

export const createCommunityStoryDeepLink = async (
  props: MediaDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createCommunityStoryDeepLinkRequestData(props), analytics)
}

export const createMomentShareDeepLink = async (
  props: MomentShareDeepLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createMomentShareDeepLinkRequestData(props), analytics)
}

export const createGuildDeepLink = async (
  props: BaseBranchLinkRequest,
  analytics: BranchAnalytics,
): DeepLinkResponse => {
  return await createBranchLink(createGuildDeepLinkRequestData(props), analytics)
}

export const createBranchWebOnlyLink = async (urlString: string): Promise<string | null> => {
  const userId = getCookie(SEGMENT_USER_ID_COOKIE)
  const url = new URL(urlString)

  if (typeof userId === 'string') {
    url.searchParams.append('refuid', userId)
  }
  const linkDataUrl = url.toString()

  const request = JSON.stringify({
    data: { $desktop_url: linkDataUrl },
  })

  return fetch(`${deepLinkService}/v1/branch`, {
    method: 'POST',
    body: request,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      return response.url
    })
    .catch((err) => {
      Bugsnag.notify('Unable to create Branch Link', err)

      return null
    })
}

const createBranchLink = async (
  linkData: BaseDeepLinkData,
  analytics?: BranchAnalytics | null,
): Promise<string | null> => {
  const userId = getCookie(SEGMENT_USER_ID_COOKIE)
  if (!linkData?.$desktop_url) return null
  let url
  try {
    url = new URL(linkData.$desktop_url)
  } catch (error) {
    Bugsnag.notify(
      'Unable to create Branch Link',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error,
    )
    return null
  }
  if (typeof userId === 'string') {
    url.searchParams.append('refuid', userId)
    linkData.$desktop_url = url.toString()
  }

  const request = JSON.stringify({
    data: linkData,
    ...analytics,
  })
  return fetch(`${deepLinkService}/v1/branch`, {
    method: 'POST',
    body: request,
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      return response.url
    })
    .catch((err) => {
      Bugsnag.notify('Unable to create Branch Link', err)

      return null
    })
}

export const getOrCreateUniqueBranchLink = async (linkData: UniqueLinkData): Promise<string> => {
  const url = new URL(linkData.$fallback_url)
  const linkDataUrl = url.toString()
  const requests: UniqueLinkData = {
    $fallback_url: linkDataUrl,
    $web_only: linkData.$web_only,
  }

  if (linkData.$marketing_title) requests.$marketing_title = linkData.$marketing_title

  try {
    const response = await fetch(`${deepLinkService}/v1/branch`, {
      method: 'POST',
      body: JSON.stringify({ data: requests }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`Branch API error: ${response.status}`)
    }

    const data = await response.json()

    if (!data.url) {
      throw new Error('No URL in Branch response')
    }

    return data.url
  } catch (error) {
    logger().error('Failed to create Branch link:', error)
    return linkDataUrl
  }
}
