import { GetSalesQuery } from '@/types/codegen-contentful'
import { NonNullableFields } from '@/utils/types'

export type ActiveSale = NonNullable<NonNullable<NonNullable<GetSalesQuery['saleCollection']>['items']>[number]>

// Required fields use NonNullable, optional fields do not.
export interface ValidSale
  extends NonNullableFields<Pick<ActiveSale, 'name' | 'abbreviatedPitch' | 'pitch' | 'promotionsCollection'>>,
    Pick<ActiveSale, 'showSaleBanner' | 'saleBannerText' | 'showSaleBannerCountdown' | 'saleBannerUrl'> {
  id: string
  category: ValidSaleCategory
  startDate?: string
  endDate?: string
  saleBannerIcon?: ValidSaleBannerIcon
}

export const VALID_SALE_CATEGORIES = ['custom', 'guild', 'guild-members-only'] as const

export type ValidSaleCategory = typeof VALID_SALE_CATEGORIES[number]

export const VALID_SALE_BANNER_ICONS = ['none', 'gift', 'giftBow'] as const

export type ValidSaleBannerIcon = typeof VALID_SALE_BANNER_ICONS[number]
