import React, { FC } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { LoadingSpinnerIcon } from '@/atoms/Icons/LoadingSpinnerIcon'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useTranslate } from '@/utils/translate/translate-client'
import { NotificationsHeader } from './NotificationsHeader'

interface NotificationsModalLoaderProps {
  backgroundClasses?: string
}

export const NotificationsModalLoader: FC<NotificationsModalLoaderProps> = ({ backgroundClasses }) => {
  const { t } = useTranslate('common')
  const { isDarkMode } = useThemeContext()

  return (
    <>
      <div className="absolute -right-0 top-10 h-[2px] w-7 bg-oxide-700" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className={classNames(
          'absolute top-10 -right-12 sm:-right-3 min-w-[96vw] sm:min-w-[380px] max-h-[85vh] py-5 mt-4 rounded-lg',
          backgroundClasses,
        )}
        style={{ boxShadow: '0px -4px 16px -1px rgba(0, 0, 0, 0.05), 0px 8px 16px -1px rgba(0, 0, 0, 0.12)' }}
      >
        <div className="mb-4 flex items-center justify-between px-4">
          <NotificationsHeader isDarkMode={isDarkMode} value={t('notifications', 'Notifications')} />
          <div className="flex flex-row gap-1"></div>
        </div>

        <div
          className={classNames(
            'flex flex-col items-center justify-center min-h-[380px]',
            isDarkMode ? 'bg-core-gray-950' : 'bg-white',
          )}
        >
          <LoadingSpinnerIcon className="mx-auto" size={50} color={'gray-600'} />
        </div>

        <div className="mt-3 px-4"></div>
      </motion.div>
    </>
  )
}
