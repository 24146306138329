import { onError } from '@apollo/client/link/error'
import { logger } from '@/utils/logging'

/**
 * Generic ApolloLink for error logging. Attempts to expose more of
 * the two types of error responses that ApolloClient will report
 * and send that data to DataDog.
 * @see https://www.apollographql.com/docs/react/data/error-handling
 */
export const logErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    logger().error('ApolloClient logErrorLink graphQLErrors', {
      graphQLErrors,
      query: operation.query,
      variables: operation.variables,
    })
  }
  if (networkError) {
    logger().error('ApolloClient logErrorLink networkError', {
      networkError,
      query: operation.query,
      variables: operation.variables,
    })
  }
})
