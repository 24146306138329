/**
 * This should never be included on the frontend as it dramatically increases bundle size.
 */
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { IncomingMessage, ServerResponse } from 'http'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { getAccessToken } from '@/auth'
import { hydraLocaleLink } from '@/services/ApolloClient/ApolloClient'
import {
  AdditionalHeaders,
  BaseClientConstructor,
  FEDERATION_CLIENT_NAME,
  FEDERATION_CLIENT_VERSION,
} from '@/services/ApolloClient/utils'
import { getRID } from '@/utils/RIDUtil'
import { sLogger } from '@/utils/logging/server-logger'
import { AccessTokenResponse } from '../../../../pages/api/auth/accessToken'
import { logErrorLink } from '../error-link'

interface ServerSideRequest {
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
  res: ServerResponse
  locale?: string
  region?: string
}

interface ServerClientConstructor extends BaseClientConstructor, ServerSideRequest {}

const serverAuthLink = ({ req, res }: ServerSideRequest) =>
  setContext((_, { headers }) => {
    return getAccessToken(req, res, { refresh: true })
      .catch((e) => {
        sLogger().warn('Unable to fetch Access Token from the server', e)
        return {} as AccessTokenResponse
      })
      .then(({ accessToken } = {}) => {
        const additionalHeaders: AdditionalHeaders = {}

        if (accessToken) {
          additionalHeaders.Authorization = accessToken
        }

        additionalHeaders['X-RID'] = getRID(req, res) as string
        additionalHeaders['X-PLATFORM'] = FEDERATION_CLIENT_NAME

        return {
          headers: {
            ...headers,
            ...additionalHeaders,
          },
        }
      })
  })

const createServerSideClient = ({ baseUrl, version, locale, region, req, res }: ServerClientConstructor) => {
  const backend = createHttpLink({ uri: `${baseUrl}/graphql` })

  return new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([logErrorLink, serverAuthLink({ req, res }), hydraLocaleLink(region, locale), backend]),
    cache: new InMemoryCache(),
    name: FEDERATION_CLIENT_NAME,
    version,
  })
}

export const getServerSideClient = ({ req, res, locale, region }: ServerSideRequest) => {
  return createServerSideClient({
    baseUrl: process.env.NEXT_PUBLIC_HYDRA as string,
    name: FEDERATION_CLIENT_NAME,
    version: FEDERATION_CLIENT_VERSION,
    locale,
    region,
    req,
    res,
  })
}
