import React, { FC } from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { TabList } from '../Tabs/TabList'

export interface NotificationsTabsProps {
  isDarkMode?: boolean
  channelList: string[]
  notificationTabIndex: number
  handleTabChange: (tabIndex: number) => void
}

export const NotificationsTabs: FC<NotificationsTabsProps> = React.memo(
  ({ isDarkMode = true, channelList, notificationTabIndex, handleTabChange }) => {
    const tabClasses =
      'focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0 rounded-full border border-solid mr-2 px-4 py-1.5 leading-4 text-xs'
    return (
      <Tab.Group selectedIndex={notificationTabIndex} onChange={handleTabChange}>
        <TabList
          names={channelList.filter((channel) => channel.toLowerCase() !== 'collectibles')}
          unSelectedClassName={classNames(isDarkMode ? 'border-white' : 'bg-white border-black text-black', tabClasses)}
          selectedClassName={classNames(
            'border-transparent',
            isDarkMode ? 'bg-white text-black' : 'bg-core-gray-950 text-white',
            tabClasses,
          )}
        />
      </Tab.Group>
    )
  },
)

NotificationsTabs.displayName = 'React.memo(NotificationsTabs)'
