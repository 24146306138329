import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactRef } from 'use-callback-ref/dist/es5/types'
import { Button } from '@/atoms/Button'
import { FlexColumn } from '@/atoms/FlexContainers'
import { CircleCheckIcon } from '@/atoms/Icons/CircleCheckIcon'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { CopyIcon } from '@/atoms/Icons/CopyIcon'
import { TicketIcon } from '@/atoms/Icons/TicketIcon'
import { CaptionSM, HeadingXS, ParagraphMD, ParagraphSM, TitleSM } from '@/atoms/Text'
import { paths } from '@/constants'
import { Modal } from '@/molecules/Modal'
import { createBasicShareLink } from '@/services/Sharing'
import { useTranslate } from '@/utils/translate/translate-client'

interface NoFeesModalProps {
  open: boolean
  onClose: () => void
  discountCodes: string[]
  projectName: string
  theatricalSlug: string
  modalRef?: ReactRef<HTMLDivElement>
}
export const ShareGuildTicketsModal = ({
  open,
  onClose,
  discountCodes,
  projectName,
  theatricalSlug,
  modalRef,
}: NoFeesModalProps) => {
  const { t } = useTranslate('common')
  const [isCopied, setIsCopied] = useState(false)
  const [shareUrl, setShareUrl] = useState<string | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        setIsCopied(false)
      }
    }
  }, [])

  useEffect(() => {
    if (!discountCodes || discountCodes.length === 0) {
      setShareUrl(null)
      return
    }

    createBasicShareLink({
      baseUrl: `${paths.base}/tickets/${theatricalSlug}`,
      params: { flow: 'guild-gift', discounts: discountCodes.join(',') },
    }).then((url: string) => setShareUrl(url))
  }, [discountCodes, theatricalSlug])

  const onCopyClick = useCallback(() => {
    if (!shareUrl) return

    if (navigator?.clipboard) {
      navigator.clipboard.writeText(shareUrl).then(() => {
        setIsCopied(true)
      })

      const timeout = setTimeout(() => {
        setIsCopied(false)
      }, 2000)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = timeout
    }
  }, [shareUrl])

  return (
    <Modal
      backgroundScreenColor="black"
      isOpen={open}
      onClose={onClose}
      backgroundOverlayClassName="!bg-black/85"
      panelClasses="relative shadow-dark-5 rounded-3xl w-full !max-w-[480px] !p-0 relative text-center"
      transition="appear"
      className="z-[99999]"
    >
      <div ref={modalRef} className="h-full w-full !p-10 !pt-12">
        <div className="absolute right-6 top-6 cursor-pointer rounded-full bg-black/10 p-1.5" onClick={onClose}>
          <CloseIcon color="black" size={18} />
        </div>
        <FlexColumn className="gap-8">
          <FlexColumn className="gap-2">
            <div className="mx-auto mb-4 rounded-full bg-warning-50 p-[18px]">
              <TicketIcon size={32} color="warm-03" />
            </div>
            <HeadingXS weight="bold">{t('giftYourGuildTickets', 'Gift Your Guild Tickets')}</HeadingXS>
            <ParagraphMD color="core-gray-700" weight="medium">
              {t(
                'byClaimingYourGuildTicketsGifting',
                'By claiming your Guild tickets or gifting them to others, you contribute to the success of {{projectName}} in theaters.',
                { projectName },
              )}
            </ParagraphMD>
          </FlexColumn>
          <FlexColumn className="gap-2">
            <TitleSM weight="semibold">{t('howToGiftYourTickets', 'How to Gift Your Tickets')}</TitleSM>
            <ParagraphSM color="core-gray-700" weight="medium">
              {t(
                'toShareYourLinkGuildTicketsV2',
                'Copy the URL below and send it to others via text message, email, or social media.',
              )}
            </ParagraphSM>
          </FlexColumn>
          <Button
            onClick={onCopyClick}
            className="mx-auto flex items-center gap-1.5 rounded-[14px] px-7 py-3"
            variant="black"
          >
            {t('copyInviteLink', 'Copy Invite Link')}
            {isCopied ? (
              <CircleCheckIcon size={20} color2="black" color1="oxide-primary" />
            ) : (
              <CopyIcon size={20} color="white" />
            )}
          </Button>
        </FlexColumn>
        <AnimatePresence>
          {isCopied && (
            <motion.div
              className="absolute inset-x-0 bottom-3.5"
              transition={{
                y: { type: 'spring', bounce: 0.3 },
                opacity: { duration: 0.3 },
                ease: 'easeInOut',
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
            >
              <CaptionSM color="core-gray-700">{t('urlCopiedToClipBoard', 'URL copied to clipboard')}</CaptionSM>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Modal>
  )
}
