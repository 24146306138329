import React, { FC, useState, useEffect, useCallback } from 'react'
import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { Button } from '@/atoms/Button'
import { CheckDoubleIcon } from '@/atoms/Icons/CheckDoubleIcon'
import { GearIcon } from '@/atoms/Icons/GearIcon'
import { MailboxIcon } from '@/atoms/Icons/MailboxIcon'
import { CaptionSM, TitleXS } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useThemeContext } from '@/contexts/ThemeContext'
import {
  UserNotification,
  useMarkNotificationsAllAsRead,
  useUserNotificationsCount,
  useUserNotificationsList,
} from '@/services/NotificationsService'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'
import { NotificationsHeader } from './NotificationsHeader'
import { NotificationsOption } from './NotificationsOption'

interface MenuArrowIconProps {
  isDarkMode: boolean
}

const MenuArrowIcon: FC<MenuArrowIconProps> = ({ isDarkMode }) => (
  <div className="absolute -top-[12px] right-14 sm:right-5 md:right-3">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="path-1-outside-1_467_17013"
        maskUnits="userSpaceOnUse"
        x="0.292893"
        y="0.272078"
        width="24.0416"
        height="24.0416"
        fill="black"
      >
        <rect fill={isDarkMode ? '#151515' : 'white'} x="0.292893" y="0.272078" width="24.0416" height="24.0416" />
        <path d="M1 13L12.3137 1.68629L23.6274 13L12.3137 24.3137L1 13Z" />
      </mask>
      <path d="M1 13L12.3137 1.68629L23.6274 13L12.3137 24.3137L1 13Z" fill={isDarkMode ? '#151515' : 'white'} />
      <path
        d="M12.3137 1.68629L13.0208 0.979185L12.3137 0.272078L11.6066 0.979185L12.3137 1.68629ZM1.70711 13.7071L13.0208 2.3934L11.6066 0.979185L0.292893 12.2929L1.70711 13.7071ZM11.6066 2.3934L22.9203 13.7071L24.3345 12.2929L13.0208 0.979185L11.6066 2.3934Z"
        fill={isDarkMode ? '#151515' : 'white'}
        mask="url(#path-1-outside-1_467_17013)"
      />
    </svg>
  </div>
)
interface NotificationsModalProps {
  fetchOnlyNew?: boolean
  onClose: () => void
  isOpen: boolean
  notificationsCount?: number | null
  backgroundClasses?: string
}

export const NotificationsModal: FC<NotificationsModalProps> = ({
  fetchOnlyNew = false,
  onClose,
  isOpen,
  notificationsCount,
  backgroundClasses,
}) => {
  const { t } = useTranslate('common')
  const router = useRouter()
  const track = useSafeTrack()
  const [loaded, setLoaded] = useState(false)
  const { isDarkMode } = useThemeContext()
  const notificationsListInput = fetchOnlyNew
    ? { cursor: '0', take: 4, where: { read: false } }
    : { cursor: '0', take: 4 }
  const { markNotificationsAllAsRead } = useMarkNotificationsAllAsRead()
  const { notifications, refetchList } = useUserNotificationsList(notificationsListInput)
  const { refetchCount } = useUserNotificationsCount()
  const [isUnreadOpen, setIsUnreadOpen] = useState(false)
  const [notificationsList, setNotificationsList] = useState(notifications)

  const closeIconColor = isDarkMode ? 'white' : 'gray-800'
  const modalClasses = isDarkMode ? 'bg-core-gray-950 shadow-dark-4' : 'bg-white text-black shadow-light-1'

  const handleUnreadOnChange = useCallback(() => {
    setIsUnreadOpen(false)
  }, [])

  const handleModalClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setIsUnreadOpen(!isUnreadOpen)
    },
    [isUnreadOpen],
  )

  const handleMarkAllUnread = useCallback(async () => {
    await markNotificationsAllAsRead()
    const updatedArray = JSON.parse(JSON.stringify(notifications))
    updatedArray.forEach((notification: UserNotification) => (notification.read = true))
    track('Notification Marked All as Read')
    setNotificationsList(updatedArray)
    refetchList()
    onClose()
    refetchCount()
    setIsUnreadOpen(!isUnreadOpen)
  }, [
    isUnreadOpen,
    markNotificationsAllAsRead,
    setNotificationsList,
    refetchList,
    onClose,
    refetchCount,
    setIsUnreadOpen,
    notifications,
    track,
  ])

  useEffect(() => {
    if (isOpen && !loaded) {
      setLoaded(true)
    }
  }, [isOpen, loaded, notificationsCount])

  const noEmptyNotifications = Boolean(notifications?.[0])

  const handleMoreNotificationsClicked = useCallback(() => {
    track('Notification View More Clicked')
    router.push({ pathname: paths.notifications.index, query: { isDarkMode: isDarkMode } })
  }, [router, isDarkMode, track])

  return (
    <>
      <div className="absolute -right-0 top-10 h-[2px] w-7 bg-oxide-700" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className={classNames(
          'absolute top-10 -right-12 sm:-right-3 min-w-[96vw] sm:min-w-[380px] max-h-[85vh] py-5 mt-4 rounded-lg',
          backgroundClasses,
        )}
        style={{ boxShadow: '0px -4px 16px -1px rgba(0, 0, 0, 0.05), 0px 8px 16px -1px rgba(0, 0, 0, 0.12)' }}
      >
        <MenuArrowIcon isDarkMode={isDarkMode} />
        <div className="mb-4 flex items-center justify-between px-4">
          <NotificationsHeader isDarkMode={isDarkMode} value={t('notifications', 'Notifications')} />
          <div className="flex flex-row gap-1">
            <Listbox as="div" onChange={handleUnreadOnChange} value={isUnreadOpen} aria-label="Mark as read">
              <Listbox.Button onClick={handleModalClick}>
                <div className="cursor-pointer">
                  <GearIcon size={24} color={closeIconColor} />
                </div>
              </Listbox.Button>
              {isUnreadOpen && (
                <>
                  <div
                    onClick={handleMarkAllUnread}
                    className={classNames(
                      'absolute z-30 -top-6 right-6 rounded-lg min-w-[88vw] sm:min-w-[332px] flex flex-row items-center px-4 py-2 cursor-pointer',
                      modalClasses,
                    )}
                  >
                    <CheckDoubleIcon size={24} color={isDarkMode ? 'white' : 'black'} />
                    <TitleXS className="ml-2 capitalize">{t('markAllAsRead', 'Mark all as read')}</TitleXS>
                  </div>
                </>
              )}
            </Listbox>
          </div>
        </div>
        {noEmptyNotifications ? (
          <div>
            {notificationsList?.map(
              (notification) =>
                notification && (
                  <NotificationsOption
                    key={notification.id}
                    isUnread={!notification?.read}
                    notification={notification as UserNotification}
                    isDarkMode={isDarkMode}
                    selected={true}
                  />
                ),
            )}
          </div>
        ) : (
          <div
            className={classNames(
              'flex min-h-[264px] flex-col items-center pt-4',
              isDarkMode ? 'bg-core-gray-950' : 'bg-white',
            )}
          >
            <MailboxIcon size={60} color={isDarkMode ? 'gray-500' : 'core-gray-800'} />
            <CaptionSM className={classNames('ml-2', isDarkMode ? 'text-gray-500' : 'text-core-gray-800')}>
              {t('noNotifications', 'No Notifications')}
            </CaptionSM>
          </div>
        )}
        <div className="mt-3 px-4">
          <Button
            onClick={handleMoreNotificationsClicked}
            className="w-full"
            variant={isDarkMode ? 'white' : 'black'}
            outline
          >
            <CaptionSM weight="bold">{t('viewMoreNotifications', 'View More Notifications')}</CaptionSM>
          </Button>
        </div>
      </motion.div>
    </>
  )
}
