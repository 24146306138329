import { logger } from '@/utils/logging'

type SessionProperty = 'pageSlug' | 'projectSlugs' | 'originalUtms' | 'originSlug' | 'torchSubmissionPromoCode'

export const setSessionProperty = (field: SessionProperty, value: unknown) => {
  if (typeof sessionStorage === 'undefined') return

  sessionStorage.setItem(field, JSON.stringify(value))
}

export function getSessionProperty<T>(name: SessionProperty, fallback?: T): T | undefined {
  if (typeof sessionStorage === 'undefined') return fallback
  try {
    const item = sessionStorage.getItem(name)
    return item ? JSON.parse(item) : fallback
  } catch (e) {
    if (e instanceof Error) {
      logger().warn(`Error reading SessionStorage for ${name}: ${e.message}`, { name }, e)
    } else {
      logger().warn(`Error reading SessionStorage for ${name}: ${String(e)}`, { name })
    }
    return fallback
  }
}
