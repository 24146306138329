import { Context, GrowthBook } from '@growthbook/growthbook-react'
import { TypedTrackFn } from '@/utils/analytics'
import { logger } from '@/utils/logging'
import { AppFeatures } from './app-features'
import { TrackingData } from './types'

export const getGrowthbook = (context: Partial<Context> = {}, trackFn: TypedTrackFn) => {
  const trackingCallback = async (experiment: TrackingData['experiment'], result: TrackingData['result']) => {
    const resultValue = shouldStringifyValue(result.value) ? JSON.stringify(result.value) : result.value
    await trackFn('Experiment Impression', { name: experiment.key, variation: resultValue })
  }

  return new GrowthBook<AppFeatures>({
    apiHost: 'https://web.growthbook-proxy.angelstudios.com',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    subscribeToChanges: true,
    backgroundSync: true,
    enableDevMode: true,
    trackingCallback,
    ...context,
    attributes: {
      ...(context.attributes || {}),
      urlParams: getUrlParams(),
    },
  })
}

export const shouldStringifyValue = (value: unknown) => {
  return typeof value === 'object' || Array.isArray(value)
}

interface UrlParamsCache {
  params: Record<string, string>
  urlString: string
}

let urlParamsCache: UrlParamsCache | null = null
const getUrlParams = (): Record<string, string> => {
  try {
    if (typeof window === 'undefined') {
      return {}
    }

    const currentUrlString = window.location.search

    if (urlParamsCache?.urlString === currentUrlString) {
      return urlParamsCache.params
    }

    const params = Object.fromEntries(new URLSearchParams(currentUrlString))
    urlParamsCache = {
      params,
      urlString: currentUrlString,
    }

    return params
  } catch (error) {
    logger().error('Error parsing URL parameters:', error)
    return {}
  }
}
