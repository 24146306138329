import React from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import { ExternalLink } from '@/atoms/ExternalLink'
import { QuestionMarkFilled } from '@/atoms/Icons/QuestionMarkFilled'
import { InternalLink } from '@/atoms/InternalLink'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { paths } from '@/constants/paths'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { AccelerationFundLink, AngelFundingLink } from '@/molecules/InvestLink'
import { LanguageMenu } from '@/molecules/LanguageSelector'
import { NotificationsMenu } from '@/molecules/Notifications'
import { RegionSelector } from '@/molecules/RegionSelector'
import { OmniboxSearchInput } from '@/organisms/Search'
import { MobileNavItemProps } from '@/organisms/SiteNav/MobileSiteNav/MobileNav'
import { NavMenu } from '@/organisms/SiteNav/NavMenu'
import { useSiteNavContext } from '@/organisms/SiteNav/SiteNavContext'
import { UserMenu } from '@/organisms/SiteNav/UserMenu'
import { useUser } from '@/services/UserService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

export interface DesktopSiteNavProps {
  isGuildMember?: boolean
}

export const DesktopSiteNav: React.FC<DesktopSiteNavProps> = ({ isGuildMember = false }) => {
  const { t } = useTranslate('common')
  const {
    handleOpenInvestMenu,
    handleOpenWatchMenu,
    handleOpenComingSoonMenu,
    handleOpenGuildMenu,
    handleOpenNavMenu,
    handleCloseNavMenu,
    openedNavMenu,
    handleOpenRegionModal,
    handleCloseLanguageModal,
    hideNavigation,
    isInvestMenuOpen,
    isWatchMenuOpen,
    isComingSoonMenuOpen,
    isGuildMenuOpen,
    notificationsCount,
    regions,
    fetchOnlyNew: shouldFetchOnlyNewNotifications,
    shouldUseDarkMode,
    showLanguageAndHelpLinks,
    showNotifications: shouldShowNotifications,
    variant,
    variantStickyClassNames,
  } = useSiteNavContext()

  return (
    <GBFadeInExperiment defaultValue={false} experimentName="remove-pif-from-nav">
      <ExperimentVariation variation={true}>
        <div
          className={classNames(
            'top-0 inset-x-0 z-50 hidden lg:flex h-16 w-full flex-row items-center justify-between px-6 py-4',
            variant === 'sticky' ? variantStickyClassNames : 'absolute',
          )}
        >
          <div className="flex flex-row items-center justify-start space-x-2 xl:space-x-6">
            {/* Angel Logo */}
            <LogoLink shouldUseDarkMode={shouldUseDarkMode} />

            {/* Site Navigation v2*/}
            <When condition={!hideNavigation}>
              <nav aria-labelledby="desktop-nav-title">
                <h2 id="desktop-nav-title" className="sr-only">
                  <Translate t={t} i18nKey="desktopMenu">
                    Desktop Menu
                  </Translate>
                </h2>
                <ul className="flex flex-row space-x-1 xl:space-x-6">
                  <li>
                    <NavMenu
                      label={t('menuLinkWatch', 'Watch')}
                      href={paths.watch.index}
                      onOpen={handleOpenWatchMenu}
                      isOpen={isWatchMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="watch-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('christian', 'Christian')}
                        href={paths.category.christianMovies}
                        id="christian-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('comedy', 'Comedy')}
                        href={paths.category.comedyTvShows}
                        id="comedy-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('drama', 'Drama')}
                        href={paths.category.drama}
                        id="drama-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('documentary', 'Documentary')}
                        href={paths.category.documentaries}
                        id="documentary-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('kidsShows', 'Kids Shows')}
                        href={paths.category.kidsShows}
                        id="kids-category"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkNewReleases', 'New Releases')}
                      href={paths.tickets.index}
                      onOpen={handleOpenComingSoonMenu}
                      isOpen={isComingSoonMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="new-releases-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('inTheaters', 'In Theaters')}
                        href={paths.tickets.index}
                        id="in-theaters"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('comingSoon', 'Coming Soon')}
                        href={paths.comingSoon.index}
                        id="coming-soon"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkGuild', 'Guild')}
                      href={
                        isGuildMember
                          ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                          : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                      }
                      onOpen={handleOpenGuildMenu}
                      isOpen={isGuildMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="guild-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={isGuildMember ? t('myBenefits', 'My Benefits') : t('learnMore', 'Learn More')}
                        href={
                          isGuildMember
                            ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                            : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                        }
                        id="guild-dashboard-join"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('menuLinkSubmitYourProject', 'Submit Your Project')}
                        href={paths.filmmakers.index}
                        id="submit-your-project"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('angelImpact', 'Angel Impact')}
                        href={paths.guild.impact}
                        id="guild-impact"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('guildVoting', 'Guild Voting')}
                        href={paths.guild.voting}
                        id="submit-your-project"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkInvest', 'Invest')}
                      href={paths.torches.index}
                      onOpen={handleOpenInvestMenu}
                      isOpen={isInvestMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="invest-menu"
                    >
                      <NavMenu.Item
                        Element={AngelFundingLink as MobileNavItemProps['Element']}
                        label={t('menuLinkAngelFunding', 'Angel Funding')}
                        href={paths.external.invest.index}
                        linkContext="menu-link"
                        id="angel-funding"
                        role="menuitem"
                        disableAutoNavigate
                      />
                      <NavMenu.Item
                        Element={AccelerationFundLink}
                        label={t('menuLinkAccelerationFund', 'Acceleration Fund')}
                        href={paths.external.accelerationFund.index}
                        linkContext="menu-link"
                        id="acceleration-fund"
                        role="menuitem"
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu.Item
                      Element={ExternalLink}
                      label={t('menuLinkShop', 'Shop')}
                      href={paths.external.shop.index}
                      id="shop"
                    />
                  </li>
                  <li>
                    <NavMenu.Item Element={InternalLink} label={t('blog', 'Blog')} href={paths.blog.index} id="blog" />
                  </li>
                </ul>
              </nav>
            </When>
          </div>

          {/* User Nav */}
          <div className="flex flex-row items-center space-x-2 xl:space-x-6">
            <OmniboxSearchInput />
            {regions && regions?.length > 0 && (
              <RegionSelector
                onRegionModalOpen={handleOpenRegionModal}
                onLanguageModalClose={handleCloseLanguageModal}
                onClose={handleCloseNavMenu}
                onOpen={() => handleOpenNavMenu('region')}
                isOpen={openedNavMenu === 'region'}
                isDarkMode={shouldUseDarkMode}
                regions={regions}
              />
            )}
            {!showLanguageAndHelpLinks && (
              <>
                <LanguageMenu
                  onOpen={() => handleOpenNavMenu('language')}
                  isOpen={openedNavMenu === 'language'}
                  onClose={handleCloseNavMenu}
                  isDarkMode={shouldUseDarkMode}
                />
                <ExternalLink aria-label={t('help', 'Help')} href={paths.external.zendesk.support}>
                  <QuestionMarkFilled
                    color1={shouldUseDarkMode ? 'white' : 'none'}
                    color2={shouldUseDarkMode ? 'gray-950' : 'black'}
                  />
                </ExternalLink>
              </>
            )}
            {shouldShowNotifications && (
              <NotificationsMenu
                notificationsCount={notificationsCount}
                fetchOnlyNew={shouldFetchOnlyNewNotifications}
                isOpen={openedNavMenu === 'notification'}
                onClose={handleCloseNavMenu}
                onOpen={() => handleOpenNavMenu('notification')}
                isDarkMode={shouldUseDarkMode}
              />
            )}
            <UserMenu loginButtonVariant={shouldUseDarkMode ? 'white' : 'black'} />
          </div>
        </div>
      </ExperimentVariation>
      <ExperimentVariation variation={false}>
        <div
          className={classNames(
            'top-0 inset-x-0 z-50 hidden xl:flex h-16 w-full flex-row items-center justify-between px-6 py-4',
            variant === 'sticky' ? variantStickyClassNames : 'absolute',
          )}
        >
          <div className="flex flex-row items-center justify-start space-x-2 xl:space-x-6">
            {/* Angel Logo */}
            <LogoLink shouldUseDarkMode={shouldUseDarkMode} />

            {/* Site Navigation v2*/}
            <When condition={!hideNavigation}>
              <nav aria-labelledby="desktop-nav-title">
                <h2 id="desktop-nav-title" className="sr-only">
                  <Translate t={t} i18nKey="desktopMenu">
                    Desktop Menu
                  </Translate>
                </h2>
                <ul className="flex flex-row space-x-1 xl:space-x-6">
                  <li>
                    <NavMenu
                      label={t('menuLinkWatch', 'Watch')}
                      href={paths.watch.index}
                      onOpen={handleOpenWatchMenu}
                      isOpen={isWatchMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="watch-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('christian', 'Christian')}
                        href={paths.category.christianMovies}
                        id="christian-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('comedy', 'Comedy')}
                        href={paths.category.comedyTvShows}
                        id="comedy-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('drama', 'Drama')}
                        href={paths.category.drama}
                        id="drama-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('documentary', 'Documentary')}
                        href={paths.category.documentaries}
                        id="documentary-category"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('kidsShows', 'Kids Shows')}
                        href={paths.category.kidsShows}
                        id="kids-category"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkNewReleases', 'New Releases')}
                      href={paths.tickets.index}
                      onOpen={handleOpenComingSoonMenu}
                      isOpen={isComingSoonMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="new-releases-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('inTheaters', 'In Theaters')}
                        href={paths.tickets.index}
                        id="in-theaters"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('comingSoon', 'Coming Soon')}
                        href={paths.comingSoon.index}
                        id="coming-soon"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkGuild', 'Guild')}
                      href={
                        isGuildMember
                          ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                          : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                      }
                      onOpen={handleOpenGuildMenu}
                      isOpen={isGuildMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="guild-menu"
                    >
                      <NavMenu.Item
                        Element={InternalLink}
                        label={isGuildMember ? t('myBenefits', 'My Benefits') : t('learnMore', 'Learn More')}
                        href={
                          isGuildMember
                            ? `${paths.guild.index}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                            : `${paths.guild.join}?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav`
                        }
                        id="guild-dashboard-join"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('menuLinkSubmitYourProject', 'Submit Your Project')}
                        href={paths.filmmakers.index}
                        id="submit-your-project"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('angelImpact', 'Angel Impact')}
                        href={paths.guild.impact}
                        id="guild-impact"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                      <NavMenu.Item
                        Element={InternalLink}
                        label={t('guildVoting', 'Guild Voting')}
                        href={paths.guild.voting}
                        id="submit-your-project"
                        linkContext="menu-link"
                        role="menuitem"
                        onClick={handleCloseNavMenu}
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu.Item
                      Element={InternalLink}
                      label={t('menuLinkPayItForwardv2', 'Pay it Forward')}
                      href={paths.payItForward.index}
                      id="pay-it-forward"
                    />
                  </li>
                  <li>
                    <NavMenu
                      label={t('menuLinkInvest', 'Invest')}
                      href={paths.torches.index}
                      onOpen={handleOpenInvestMenu}
                      isOpen={isInvestMenuOpen}
                      onClose={handleCloseNavMenu}
                      id="invest-menu"
                    >
                      <NavMenu.Item
                        Element={AngelFundingLink as MobileNavItemProps['Element']}
                        label={t('menuLinkAngelFunding', 'Angel Funding')}
                        href={paths.external.invest.index}
                        linkContext="menu-link"
                        id="angel-funding"
                        role="menuitem"
                        disableAutoNavigate
                      />
                      <NavMenu.Item
                        Element={AccelerationFundLink}
                        label={t('menuLinkAccelerationFund', 'Acceleration Fund')}
                        href={paths.external.accelerationFund.index}
                        linkContext="menu-link"
                        id="acceleration-fund"
                        role="menuitem"
                      />
                    </NavMenu>
                  </li>
                  <li>
                    <NavMenu.Item
                      Element={ExternalLink}
                      label={t('menuLinkShop', 'Shop')}
                      href={paths.external.shop.index}
                      id="shop"
                    />
                  </li>
                  <li>
                    <NavMenu.Item Element={InternalLink} label={t('blog', 'Blog')} href={paths.blog.index} id="blog" />
                  </li>
                </ul>
              </nav>
            </When>
          </div>

          {/* User Nav */}
          <div className="flex flex-row items-center space-x-2 xl:space-x-6">
            <OmniboxSearchInput />
            {regions && regions?.length > 0 && (
              <RegionSelector
                onRegionModalOpen={handleOpenRegionModal}
                onLanguageModalClose={handleCloseLanguageModal}
                onClose={handleCloseNavMenu}
                onOpen={() => handleOpenNavMenu('region')}
                isOpen={openedNavMenu === 'region'}
                isDarkMode={shouldUseDarkMode}
                regions={regions}
              />
            )}
            {!showLanguageAndHelpLinks && (
              <>
                <LanguageMenu
                  onOpen={() => handleOpenNavMenu('language')}
                  isOpen={openedNavMenu === 'language'}
                  onClose={handleCloseNavMenu}
                  isDarkMode={shouldUseDarkMode}
                />
                <ExternalLink aria-label={t('help', 'Help')} href={paths.external.zendesk.support}>
                  <QuestionMarkFilled
                    color1={shouldUseDarkMode ? 'white' : 'none'}
                    color2={shouldUseDarkMode ? 'gray-950' : 'black'}
                  />
                </ExternalLink>
              </>
            )}
            {shouldShowNotifications && (
              <NotificationsMenu
                notificationsCount={notificationsCount}
                fetchOnlyNew={shouldFetchOnlyNewNotifications}
                isOpen={openedNavMenu === 'notification'}
                onClose={handleCloseNavMenu}
                onOpen={() => handleOpenNavMenu('notification')}
                isDarkMode={shouldUseDarkMode}
              />
            )}
            <UserMenu loginButtonVariant={shouldUseDarkMode ? 'white' : 'black'} />
          </div>
        </div>
      </ExperimentVariation>
    </GBFadeInExperiment>
  )
}

function LogoLink(props: { shouldUseDarkMode: boolean }) {
  const { isLoggedIn } = useUser()

  return (
    <InternalLink href={isLoggedIn ? paths.watch.index : paths.home}>
      <When condition={props.shouldUseDarkMode}>
        <AngelLogo className="min-w-[50px]" color="white" />
      </When>
      <When condition={!props.shouldUseDarkMode}>
        <AngelLogo className="min-w-[50px]" color="black" />
      </When>
    </InternalLink>
  )
}
